import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { ErrorMessage } from '@hookform/error-message';
import ReCAPTCHA from 'react-google-recaptcha';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { Helmet } from 'react-helmet';

import PrivatePolicy from '../../lib/pdf/PrivacyPolicy.pdf';
import useCaptcha from '../../hooks/useCaptcha';
import { sendContact, SendContactParams } from '../../lib/api/requests';
import { LoadingLoader } from '../../components/Loader/LopadingLoader';

type FormValues = {
  email: string;
  name: string;
  company?: string;
  website?: string;
  note?: string;
};

const Contact = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const { recaptchaValue, recaptchaRef, handleRecaptchaChange } = useCaptcha();

  const { register, handleSubmit, control, formState, reset } = useForm<FormValues>({
    defaultValues: {},
    mode: 'onBlur'
  });

  const onSubmit = async (data: FormValues) => {
    console.log('data: ', data);
    try {
      setLoading(true);
      setError(null);
      setSuccess(false);
      const recaptchaValue = recaptchaRef.current?.getValue();

      const params: SendContactParams = {
        ...data,
        recaptchaValue: recaptchaValue as string
      };

      const contact = await sendContact(params);

      if (contact) {
        console.log('contact: ', contact);
        setSuccess(true);
        reset();
        setTimeout(() => {
          setSuccess(false);
          window.scrollTo({ top: 0, behavior: 'smooth' });
          window.location.reload();
        }, 3000);
      }
    } catch (e: any) {
      console.error('Error: ', e);
      if (e.response) {
        setError(e.response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'ContactPage',
    url: 'https://accessibridge.com/contact',
    name: 'Contact | Accessibility Audit Report',
    description: 'Get in touch with us to learn more about our services.',
    keywords: 'accessibility, audit, report, contact, services, accesi bridge',
    contactPoint: {
      '@type': 'ContactPoint',
      email: 'support@mnb.solutions',
      contactType: 'customer service',
      areaServed: 'DE',
      availableLanguage: 'English, German'
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact | Accessibility Audit Report</title>
        <link rel="canonical" href="https://accessibridge.com/contact" />
        <meta name="description" content="Get in touch with us to learn more about our services." />
        <meta name="keywords" content="accessibility, audit, report, contact, services, accesi bridge" />
        <meta name="author" content="MNB solutions" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Contact | Accessibility Audit Report" />
        <meta property="og:description" content="Get in touch with us to learn more about our services." />
        <meta property="og:url" content="https://accessibridge.com/contact" />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>
      <main role="main" className={'main-bg'} style={{ minHeight: '100dvh', height: '100%' }}>
        <Paper
          sx={(theme) => ({
            p: 5,
            margin: 'auto',
            maxWidth: 1250,
            minHeight: 'inherit',
            display: 'flex',
            alignItems: 'center',
            // flexGrow: 1,
            backgroundColor: 'transparent',
            ...theme.applyStyles('dark', {
              backgroundColor: '#1A2027'
            })
          })}
          elevation={0}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} pb={2}>
                <Typography variant={'h5'} component={'h1'}>
                  Get in touch
                </Typography>
                {/*<Typography variant={'body2'}>Enter your website URL and email to get your report</Typography>*/}
              </Grid>

              <Grid item xs={12}>
                <TextField
                  {...register('email', {
                    required: true
                  })}
                  id="email"
                  type="email"
                  label="Email"
                  autoComplete="email"
                  variant="outlined"
                  error={!!formState.errors.email}
                  fullWidth
                  required
                />
                <ErrorMessage
                  errors={formState.errors}
                  name={`email`}
                  as={<Typography variant={'body2'} color={'error'} />}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register('name', { required: true })}
                  id="name"
                  type="text"
                  autoComplete="name"
                  name="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register('company', { required: false })}
                  id="company"
                  type="text"
                  autoComplete="company"
                  name="company"
                  label="Company"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register('website', { required: false })}
                  id="website"
                  type="text"
                  autoComplete="website"
                  name="website"
                  label="Website"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register('note', { required: false })}
                  id="note"
                  type="text"
                  name="note"
                  label="Note"
                  multiline
                  minRows={4}
                  maxRows={8}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} mt={1}>
                <Typography variant={'body2'} pb={2}>
                  The processing of personal data takes place for the purpose of handling your request. More information
                  is available{' '}
                  <a href={PrivatePolicy} className={'link'}>
                    HERE
                  </a>
                  .
                </Typography>
                <Typography variant={'body2'}>
                  By clicking "GET IN TOUCH", you agree to our Terms of Services and Privacy Policy. The personal
                  information you provide, including your email, name, company, website, and notes, will be securely
                  stored for up to six months. You may withdraw your consent at any time by contacting us at{' '}
                  <a href={'mailto:support@mnb.solutions'} className={'email-link'}>
                    support@mnb.solutions
                  </a>
                  .
                </Typography>
              </Grid>

              <Grid item xs={12} mt={2}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  onChange={handleRecaptchaChange}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                />
              </Grid>

              {error && (
                <Grid item xs={12}>
                  <Alert severity="error">{error}</Alert>
                </Grid>
              )}
              {success && (
                <Grid item xs={12}>
                  <Alert severity="success">Your message has been sent successfully.</Alert>
                </Grid>
              )}

              <Grid item xs={12} mt={0} justifyContent={'center'} display={'flex'}>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  type={'submit'}
                  disabled={formState.isValid && !recaptchaValue}
                >
                  Get in touch
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
        {loading && <LoadingLoader />}
      </main>
    </>
  );
};

export default Contact;
