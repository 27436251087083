import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import { privacyPolicyRoute } from '../Browser/routes';

const Footer = () => {
  return (
    <footer className={'footer-bg'} role="contentinfo">
      <Box pt={10} />
      <Grid container justifyContent={'center'} justifyItems={'center'}>
        {/*<LazyShow>*/}
        <Box py={2} />
        <Grid item xs={12} pt={2} textAlign={'center'}>
          <Typography variant={'body1'} textAlign={'center'} display={'inline'}>
            © {new Date().getFullYear()}{' '}
          </Typography>
          <a href="https://mnb.solutions" className={'link'}>
            MNB solutions
          </a>
          <Typography variant={'body1'} textAlign={'center'} display={'inline'}> / </Typography>
          <Link to={privacyPolicyRoute()} className={'link'}>
            Privacy Policy
          </Link>
          <Typography variant={'body1'} textAlign={'center'} display={'inline'}> / </Typography>
          <a href={'https://www.linkedin.com/company/mnb-solutions'} className={'link'}>
            LinkedIn
          </a>
          <Typography variant={'body1'} textAlign={'center'} display={'inline'}>/</Typography>
          <a href={'https://www.instagram.com/mnbsolutions/'} className={'link'}>
            Instagram
          </a>
          <Typography variant={'body1'} textAlign={'center'} display={'inline'}> / </Typography>
          <a href={'https://www.facebook.com/MNBSolutions/'} className={'link'}>
            Facebook
          </a>
          <Typography variant={'body1'} textAlign={'center'} display={'inline'}> / </Typography>
          <a href={'https://www.mnb.solutions/contact/'} className={'link'}>
            Imprint
          </a>
        </Grid>
        {/*</LazyShow>*/}
      </Grid>
    </footer>
  );
};

export default Footer;
